import React from 'react';
import { Flex, Text, Box } from 'rebass';
import BaseContainer from '../common/layouts/BaseContainer';
import content from './index';

class StatuePage extends React.Component {
  render() {
    return (
      <BaseContainer my={50} px={[20, 35]}>
        <Flex width={1} flexDirection="column">
          <Flex width={1} alignItems="center" flexDirection="column">
            <Text fontFamily="Comfortaa-Semibold" fontSize={24}>
              Regulamin Strefy Rabatów Aimedo BENEFIT
            </Text>
            <Text fontSize={20} mt={10} mb={20}>
              Obowiązuje od 11 grudnia 2020
            </Text>
          </Flex>
          {content.map(({ title, subtitle, list }, contentIdx) => (
            <Flex
              key={`content-${contentIdx}`}
              flexDirection="column"
              width={1}
            >
              <Text
                width={1}
                textAlign="center"
                fontFamily="Comfortaa-Semibold"
                my={15}
              >
                {title}
              </Text>
              {subtitle && <Text my={15}>{subtitle}</Text>}
              <ol style={{ listStyle: 'decimal' }}>
                {list.map(({ description, children }, idx) => (
                  <Text
                    lineHeight={1.2}
                    as="li"
                    mb="5px"
                    key={`${title}-${idx}`}
                  >
                    {description}
                    <Box as="ul" sx={{ listStyle: 'circle' }} ml={20}>
                      {(children || []).map((subItem, i) => (
                        <Text
                          lineHeight={1.2}
                          mb="5px"
                          as="li"
                          key={`subitem-desc-${idx}-${title}-${i}`}
                        >
                          {subItem.description}
                        </Text>
                      ))}
                    </Box>
                  </Text>
                ))}
              </ol>
            </Flex>
          ))}
        </Flex>
      </BaseContainer>
    );
  }
}

export default StatuePage;
