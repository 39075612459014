import React from 'react';
import styled from '@emotion/styled';
import { Image, Flex } from 'rebass';

const LoaderImage = styled(Image)`
  animation: spinner 0.6s linear infinite;

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
`;

const Loader = props => (
  <Flex width={1} alignItems="center" justifyContent="center" {...props}>
    <LoaderImage src="/images/loader.svg" />
  </Flex>
);

export default Loader;
