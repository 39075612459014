import React from 'react';
import { FormSpy, Field, Form } from 'react-final-form';
import { Flex } from 'rebass';
import Stepper from '../common/form/Stepper';

const SummaryQuantityForm = ({ offer, onSubmit, initialValues }) => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialValues}
    render={({ handleSubmit, values, form: { reset } }) => (
      <form onSubmit={handleSubmit}>
        <FormSpy
          subscription={{ submitError: true }}
          onChange={() => reset()}
        />
        <Flex alignItems="flex-end">
          <Field
            label=""
            name="quantity"
            max={
              (
                (offer.vouchers || []).find(
                  v => v.id === +(values || {}).voucherId,
                ) || {}
              ).availableCodesAmount
            }
            component={Stepper}
            afterChange={handleSubmit}
          />
        </Flex>
      </form>
    )}
  />
);

export default SummaryQuantityForm;
