import React from 'react';
import { Text, Flex } from 'rebass';
import styled from '@emotion/styled';
import BaseContainer from './BaseContainer';

const Banner = styled(Flex)`
  position: relative;
  overflow: hidden;
  width: 100%;
  &:before {
    content: '';
    top: 0;
    position: absolute;
    width: 100%;
    height: 1000px;
    z-index: 0;
    background: url('/images/banner-discounts.svg') center center no-repeat;
    background-size: contain;
    background-position: 0 140px;
    @media (min-width: 400px) {
      background-position: 0 45px;
    }
    @media (min-width: 600px) {
      background-position: 0 5px;
    }
    @media (min-width: 930px) {
      background-position: 0 -35px;
    }
    @media (min-width: 1250px) {
      background-position: 0 -115px;
    }
    @media (min-width: 1600px) {
      background-position: 0 -165px;
    }
  }
`;
const Heading = styled(Text)`
  @media screen and (max-width: 1430px) and (min-width: 768px) {
    margin-left: 120px;
  }
  @media (min-width: 340px) {
    margin-bottom: 95px;
  }
  @media (min-width: 400px) {
    margin-bottom: 135px;
  }
  @media (min-width: 500px) {
    margin-bottom: 175px;
  }
  @media (min-width: 800px) {
    margin-bottom: 225px;
  }
  @media (min-width: 930px) {
    margin-bottom: 270px;
  }
  @media (min-width: 1250px) {
    margin-bottom: 275px;
  }
  @media (min-width: 1600px) {
    margin-bottom: 300px;
  }
`;

class LayoutWithBanner extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <Banner flexDirection="column" justifyContent="center" bg="white" py={40}>
        <BaseContainer width="100%" maxWidth="1200px">
          <Heading
            px={20}
            fontSize={[16, 20]}
            lineHeight="30px"
            mb="40px"
            maxWidth="500px"
            fontFamily="Comfortaa-Bold"
          >
            Sprawdź aktualne rabaty i oferty specjalne dostępne dla Ciebie!
          </Heading>
          {children}
        </BaseContainer>
      </Banner>
    );
  }
}

export default LayoutWithBanner;
