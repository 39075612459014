/* eslint-disable */

export default [
  {
    title: '§ 1 Terminologia',
    subtitle: 'Użyte w niniejszym Regulaminie określenia oznaczają:',
    list: [
      {
        description:
          'Wydawca – epruf s.a. z siedzibą w Łodzi, ul. Zbąszyńska 3, 91-342 Łódź, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy dla Łodzi-Śródmieścia w Łodzi, XX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000319410, NIP: 9471958279, kapitał zakładowy 700 000 złotych w całości wpłacony.',
      },
      {
        description:
          'Kod rabatowy Akceptanta – kod rabatowy udostępniony w Serwisie przez Akceptanta, mający postać kodu alfanumerycznego, linku do oferty specjalnej lub innej formy elektronicznej lub papierowej przyjętej przez Wydawcę. Kod umożliwia zakup produktów w placówkach handlowo-usługowych Akceptanta zgodnie z warunkami przypisanymi do kodu, w szczególności wskazane mogą zostać zasady jego użycia oraz data ważności. Kod rabatowy Akceptanta nie jest kartą płatniczą, ani instrumentem pieniądza elektronicznego w rozumieniu obowiązujących przepisów prawa.',
      },
      {
        description:
          'Akceptant – podmiot prowadzący Punkt akceptujący lub Punkty akceptujące, w których istnieje możliwość realizacji zakupów z wykorzystaniem Kodów rabatowych Akceptanta wygenerowanych w Serwisie.',
      },
      {
        description:
          'Klient – osoba fizyczna, będąca użytkownikiem Serwisu, która w ramach danego Serwisu korzysta z udostępnionych mu funkcjonalności. Dane dostępowe umożliwiające Klientowi zalogowanie się do Serwisu zostaną wygenerowane przez Wydawcę i przekazane Klientowi przez jego pracodawcę lub w inny sposób uzgodniony przez Wydawcę z pracodawcą.',
      },
      {
        description:
          'Serwis – serwis internetowy prowadzony przez Wydawcę pod adresem www.aimedorabaty.pl , w którym Klient może pobrać Kod rabatowy Akceptanta, w celu jego późniejszego wykorzystania podczas zakupów w Punktach akceptujących.',
      },
      {
        description:
          'Punkt akceptujący – prowadzone przez Akceptanta placówki handlowo-usługowe, placówki ochrony zdrowia lub zakłady lecznicze działające stacjonarnie bądź za pośrednictwem Internetu, w tym internetowe systemy składania zamówień oraz placówki realizujące zamówienia składane za pośrednictwem Internetu, (e-commerce), w których Klient może zakupić Towary z wykorzystaniem Kodu rabatowego Akceptanta.',
      },
      {
        description:
          'Towary – produkty i usługi oferowane przez Akceptantów w Punktach akceptujących, które Klient może nabyć z wykorzystaniem Kodu rabatowego Akceptanta. ',
      },
    ],
  },
  {
    title: '§ 2 Postanowienia ogólne',
    list: [
      {
        description:
          'W zakresie usług świadczonych drogą elektroniczną Regulamin jest regulaminem, o którym mowa w art. 8 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną.',
      },
      {
        description:
          'Klienci są zobowiązani do zapoznania się z treścią Regulaminu i przestrzegania jego postanowień. ',
      },
      {
        description:
          'Korzystanie z Serwisu przez Klienta jest możliwe pod warunkiem spełnienia następujących minimalnych wymogów technicznych: ',
        children: [
          {
            description:
              'komputer lub urządzenie mobilne z dostępem do Internetu,',
          },
          {
            description:
              'przeglądarka internetowa umożliwiająca wyświetlanie stron www, przy czym zaleca się korzystanie z najnowszych wersji przeglądarek (nie gwarantujemy poprawności działania przy stosowaniu przeglądarek w wersjach starszych),',
          },
        ],
      },
      {
        description:
          'W przypadku, gdy sprzęt komputerowy i oprogramowanie Klienta nie spełniają wymogów technicznych, może się to wiązać z niemożnością korzystania z Serwisu. W takim przypadku Wydawca nie ponosi odpowiedzialności za brak możliwości korzystania z Serwisu. ',
      },
    ],
  },

  {
    title:
      '§ 3 Warunki zawierania i rozwiązywania umów o świadczeniu usług drogą elektroniczną',
    list: [
      {
        description:
          'Umowa o świadczenie usług drogą elektroniczną zostaje zawarta z chwilą zalogowania się  do Serwisu. Umowa zawierana jest na czas oznaczony, to jest przez okres korzystania z Serwisu do chwili wylogowania się Klienta z Serwisu.',
      },

      {
        description:
          'Klient może rozpocząć korzystanie z Serwisu po zapoznaniu się z treścią Regulaminu i pełną akceptacją wszystkich jego postanowień.',
      },

      {
        description:
          'Klient może rozwiązać umowę o świadczenie usług drogą elektroniczną w każdej chwili. Rozwiązanie umowy jest realizowane poprzez wylogowanie się Klienta z Serwisu.',
      },
    ],
  },
  {
    title: '§ 4 Korzystanie z Serwisu',
    list: [
      {
        description:
          'Serwis umożliwia Klientom nieodpłatne pobieranie Kodów rabatowych Akceptantów w celu późniejszego dokonania zakupu Towarów w Punktach akceptujących z wykorzystaniem Kodu rabatowego Akceptanta. Wykaz Punktów akceptujących znajduje się na stronie internetowej Serwisu pod adresem www.aimedorabaty.pl.',
      },
      {
        description:
          'Dostęp do Serwisu jest ograniczony. Korzystanie z funkcjonalności Serwisu możliwe jest wyłącznie po zalogowaniu do Serwisu za pomocą przekazanych wcześniej Klientowi danych dostępowych.',
      },
      {
        description:
          'Kody rabatowe Akceptanta nie podlegają zwrotowi ani wymianie na gotówkę.',
      },
      {
        description:
          'Kody rabatowe Akceptanta mają określoną datę ważności oraz określone parametry przypisane do danego kodu, które definiują zasady ich wykorzystania podczas zakupu Towarów w Punktach akceptujących. Klient może sprawdzić termin ważności Kodu rabatowego Akceptanta w Serwisie.',
      },
      {
        description:
          'Kody rabatowe Akceptanta mogą być wykorzystywane wyłącznie przez Klienta, który je pobrał.',
      },
      {
        description:
          'Wydawca nie ponosi odpowiedzialności za Kody rabatowe Akceptanta, które zostały utracone lub uszkodzone po ich wydaniu Klientowi lub zostały przekazane przez Klienta osobie nieuprawnionej do ich wykorzystania.',
      },
      {
        description:
          'Wydawca nie ponosi odpowiedzialności za skutki braku możliwości realizacji Kodów rabatowych Akceptantów w Punktach akceptujących leżące po stronie Akceptantów lub wynikające z działania siły wyższej.',
      },
    ],
  },
  {
    title:
      '§ 5 Zasady realizacji zakupów z użyciem Kodów rabatowych Akceptantów',
    list: [
      {
        description:
          'Kod rabatowy Akceptanta można wykorzystać w Punktach akceptujących w celu zakupu Towarów oferowanych przez Akceptanta. Zakup Towarów z wykorzystaniem Kodów rabatowych Akceptanta podlega standardowej fiskalizacji w Punkcie akceptującym. ',
      },
      {
        description:
          'Zasady wykorzystania Kodu rabatowego Akceptanta w celu zakupu Towarów wskazane są w opisie Kodu rabatowego Akceptanta zamieszczonym w Serwisie. ',
      },
      {
        description:
          'Kod rabatowy Akceptanta może być wykorzystany do zakupu Towarów w Punkcie akceptującym tylko w terminie jego ważności. ',
      },
      {
        description:
          'W przypadku realizacji Kodu rabatowego Akceptanta podczas składania zamówienia na stronie internetowej Punktu akceptującego należy we wskazanym polu wpisać kod alfanumeryczny pobrany ze strony Serwisu, chyba że opis działania Kodu rabatowego Akceptanta zamieszczony w Serwisie stanowi inaczej.',
      },
      {
        description:
          'Kod rabatowy Akceptanta wykorzystywany podczas zakupu Towarów w Punkcie akceptującym obniża cenę do zapłaty za Towary zgodnie z zasadami opisanymi dla danego Kodu rabatowego Akceptanta. Transakcja może zostać sfinalizowana po zapłacie przez Klienta obniżonej ceny Towaru w dowolny akceptowany przez Akceptanta sposób.',
      },
      {
        description:
          'Reklamacje związane z działaniem Serwisu powinny być składane do Wydawcy niezwłocznie po ujawnieniu nieprawidłowości pisemnie na adres siedziby Wydawcy, telefonicznie pod numerem infolinii Wydawcy 42 612 09 91 (czynnej w dni robocze w godzinach 8.00-16.00) lub w formie elektronicznej na adres: pomoc@aimedorabaty.pl. Wydawca udzieli odpowiedzi na reklamację w terminie 14 dni od dnia złożenia reklamacji przez Klienta. Reklamacje złożone pisemnie będą uznane za skutecznie doręczone w dniu otrzymania pisma przez Wydawcę.',
      },
      {
        description:
          'Wydawca nie ponosi odpowiedzialności wobec Klientów za jakość Towarów Akceptantów, ani za jakiekolwiek poniesione w związku z tym szkody. Reklamacje związane z realizacją zakupu Towarów z wykorzystaniem Kodu rabatowego Akceptanta powinny być składane do Akceptanta według ustalonych przez niego zasad.',
      },
      {
        description:
          'Wydawca nie ponosi odpowiedzialności za korzystanie przez Klienta z Serwisu  w sposób sprzeczny z Regulaminem.',
      },
      {
        description:
          'Wydawca, z uwagi na konieczność prowadzenia prac konserwatorskich lub napraw Serwisu, a także możliwość wystąpienia innych nieoczekiwanych okoliczności,  dokłada wszelkich starań, aby zapewnić pełną techniczną funkcjonalność Serwisu, ale nie gwarantuje pełnej i stałej dostępności Serwisu i nie ponosi odpowiedzialności za szkody lub utracone korzyści powstałe w związku z brakiem możliwości czasowego korzystania z Serwisu.',
      },
    ],
  },
  {
    title: '§ 6 Odstąpienie od umowy',
    list: [
      {
        description:
          'Klienci, którzy za pośrednictwem Serwisu w formie umowy zawartej na odległość pobrali Kody rabatowe Akceptanta mogą odstąpić od umowy w ciągu 14 dni od ich pobrania bez podania przyczyny.',
      },
      {
        description:
          'Klient w celu odstąpienia od umowy powinien przesłać na adres pomoc@aimedorabaty.pl oświadczenie o odstąpieniu od umowy. Klient może odstąpić od umowy tylko w przypadku, gdy Kod rabatowy Akceptanta nie został jeszcze zrealizowany i nie minął termin, do którego miał być zrealizowany. ',
      },
      {
        description:
          'W przypadku odstąpienia od umowy Kody rabatowe Akceptanta zostaną unieważnione.',
      },
    ],
  },
  {
    title: '§ 7 Dane osobowe',
    list: [
      {
        description:
          'Administratorem danych osobowych zbieranych za pośrednictwem Serwisu jest epruf s.a. z siedzibą w Łodzi, ul. Zbąszyńska 3, 91-342 Łódź, dane kontaktowe: ul. Zbąszyńska 3, 91-342 Łódź, tel. 48 200 75 68 (zwana dalej „Administratorem”) będąca właścicielem platformy internetowej www.aimedorabaty.pl',
      },

      {
        description:
          'Administrator w celu należytej ochrony danych osobowych powołał Inspektora Ochrony Danych. Kontakt do IOD - e-mail: iod@epruf.pl',
      },

      {
        description:
          'Administrator przetwarza podane w formularzach dane osobowe Klienta w celach:',
        children: [
          {
            description:
              'realizacji usług wydawania Kodów rabatowych Akceptanta – podstawą prawną przetwarzania danych jest wykonanie umowy (art. 6 ust. 1 lit. b RODO),',
          },

          {
            description:
              'obsługa infolinii, rozpatrzenia zgłoszeń w tym rozpatrzenia reklamacji, dochodzenia i obrony w razie zaistnienia wzajemnych roszczeń – podstawą prawną przetwarzania danych jest prawnie uzasadniony interes Wydawcy bonów (art. 6 ust. 1 lit. f RODO),',
          },

          {
            description:
              'oceny jakości świadczonych usług, pozyskania opinii Klienta o działaniach Wydawcy i Akceptantów odnośnie pozyskiwania i wykorzystania Kodów rabatowych Akceptanta. Podstawą prawną przetwarzania danych osobowych jest prawnie uzasadniony interes administratora (art. 6 ust. 1 lit. f RODO),',
          },

          {
            description:
              'prowadzenia i bieżącego zarządzania kontami Serwisu w mediach społecznościowych, w tym w celu komunikowania się ze społecznością oraz organizowania wydarzeń lub promocji na zasadach określonych funkcjonalnościami poszczególnych mediów społecznościowych oraz zgodnie z ich regulaminami. Podstawą prawną przetwarzania danych jest zgoda, np. w formie kliknięcia na wtyczkę „Lubię to”, (art. 6 ust. 1 lit a RODO).',
          },
        ],
      },

      {
        description:
          'Podanie danych osobowych Klienta jest dobrowolne, lecz niezbędne w celu wykonania usług w odpowiedni sposób. Klient ma możliwość niepodawania swoich danych osobowych i w takim przypadku usługi są świadczone z wyłączeniem użycia poczty elektronicznej.',
      },

      {
        description:
          'Dane osobowe Klientów będą przetwarzane przez Wydawcę przez okres ważności Kodu rabatowego Akceptanta, a po jego zakończeniu do czasu upływu okresu przedawnienia roszczeń z tytułu korzystania z Kodu rabatowego Akceptanta, bądź w razie powstania sporu do czasu wygaśnięcia roszczeń stron. Dane osobowe przetwarzane w celach pozyskiwania opinii przechowywane będą do czasu wniesienia sprzeciwu.',
        children: [
          {
            description:
              'podmiotom dostarczającym i wspierającym systemy informatyczne stosowane przez Administratora w celu obsługi Serwisu;',
          },

          {
            description:
              'podmiotom świadczącym usługi związane z działalnością Wydawcy w celu realizacji przez niego obowiązków wynikających ze świadczonej usługi,  w szczególności w zakresie obsługi Kodów rabatowych Akceptanta, obsługi Klienta (telefonicznej i e-mailowej), a także podmiotowi świadczącemu usługę lub dostarczającemu narzędzia do kontaktu telefonicznego i elektronicznego z Klientem;',
          },

          {
            description:
              'podmiotom świadczącym usługi w zakresie zbierania opinii;',
          },

          {
            description:
              'dostawcom mediów społecznościowych, w szczególności Facebook Ireland Ltd, na mocy regulaminów tych mediów społecznościowych oraz przy zapewnieniu stosowania przez ww. podmioty adekwatnych środków technicznych i organizacyjnych zapewniających ochronę danych, a także podmiotom świadczącym usługi zarządzania i konsultingu w zakresie kont Serwisu w mediach społecznościowych, na mocy stosownych umów powierzenia przetwarzania danych osobowych oraz przy zapewnieniu stosowania przez ww. podmioty adekwatnych środków technicznych i organizacyjnych zapewniających ochronę danych.',
          },
        ],
      },

      {
        description:
          'Dane osobowe Klientów mogą być ujawniane przez Administratora podmiotom z nim współpracującym (odbiorcom danych), tj.:',
      },

      {
        description:
          'Każdej osobie, której dane są przetwarzane, w zakresie wynikającym z przepisów prawa, przysługuje prawo dostępu do swoich danych oraz ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych oraz prawo wniesienia sprzeciwu wobec przetwarzania danych, które można wykonać poprzez wysłanie wiadomość e-mail na adres pomoc@aimedorabaty.pl lub telefonicznie pod numerem tel.: 42 612 09 91.',
      },

      {
        description:
          'W przypadku wątpliwości związanych z przetwarzaniem danych osobowych każda osoba może zwrócić się do Administratora z prośbą o udzielenie informacji. Niezależnie od powyższego, każdemu przysługuje prawo wniesienia skargi do organu nadzorczego – Prezesa Urzędu Ochrony Danych Osobowych.',
      },
    ],
  },
  {
    title: '§ 8 Postanowienia końcowe',
    list: [
      {
        description:
          'Wydawca zastrzega sobie prawo do wprowadzania zmian w zakresie treści lub funkcjonalności Serwisu, w tym sposobu prezentacji lub technicznej realizacji określonych funkcjonalności. ',
      },

      {
        description:
          'W sprawach nieuregulowanych postanowieniami Regulaminu mają zastosowanie odpowiednie przepisy kodeksu cywilnego. Wybór prawa polskiego nie pozbawia Klienta będącego konsumentem ochrony przyznanej mu na podstawie przepisów, których nie można wyłączyć w drodze umowy, na mocy prawa jakie byłoby właściwe w braku wyboru, tj. prawa państwa, w którym konsument ma miejsce zwykłego pobytu, a sprzedawca (1) wykonuje swoją działalność gospodarczą lub zawodową w państwie w którym konsument ma miejsce pobytu; lub (2) w jakikolwiek sposób kieruje taką działalność do tego państwa lub do kilku państw z tym państwem włącznie; a umowa wchodzi w zakres tej działalności.',
      },

      {
        description:
          'Klient logując się do Serwisu, składa oświadczenie, że zapoznał się z niniejszym Regulaminem, nie wnosi zastrzeżeń co do jego treści i zobowiązuje się go przestrzegać.',
      },

      {
        description:
          'Utrwalenie, zabezpieczenie oraz udostępnienie Klientom treści Regulaminu następuje poprzez udostępnienie Regulaminu na stronie internetowej Serwisu w sposób umożliwiający jego bezpłatne wydrukowanie przez Klienta.',
      },

      {
        description:
          'Wydawca zastrzega sobie prawo do dokonywania zmian w Regulaminie bez podania przyczyny. Wydawca poinformuje Klientów  o każdej zmianie za pomocą wiadomości przesłanej pocztą elektroniczną na adres email podany przez Klienta z co najmniej 14-dniowym wyprzedzeniem. Dodatkowo, o każdej zmianie Regulaminu Wydawca powiadomi na stronie internetowej Serwisu najmniej 14-dniowym wyprzedzeniem.  Do Kodów rabatowych Akceptantów pobranych przez Klientów przed dniem wejścia w życie nowego brzmienia Regulaminu, stosuje się postanowienia Regulaminu w brzmieniu obowiązującym w dniu pobrania Kodów rabatowych Akceptantów.  ',
      },
    ],
  },
];
