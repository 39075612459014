import axios from 'axios';
import snakeCase from 'lodash/snakeCase';
import camelCase from 'lodash/camelCase';
import { ACCESS_TOKEN_KEY } from './constants';

export const convertKeysToSnakeCase = obj => {
  if (Array.isArray(obj)) {
    return obj.map(v => convertKeysToSnakeCase(v));
  }
  if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [snakeCase(key)]: convertKeysToSnakeCase(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

export const convertKeysToCamelCase = obj => {
  if (Array.isArray(obj)) {
    return obj.map(v => convertKeysToCamelCase(v));
  }
  if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: convertKeysToCamelCase(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  params: {},
});

export const getToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

export const setToken = ({ token }) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const clearToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  delete axiosInstance.defaults.headers.common.Authorization;
};

const AUTH_TOKEN = getToken();
if (AUTH_TOKEN) {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`;
}

axiosInstance.interceptors.response.use(
  response => ({ ...response, data: convertKeysToCamelCase(response.data) }),
  undefined,
);

export default axiosInstance;
