import React from 'react';
import { Box } from 'rebass';
import styled from '@emotion/styled';
import { Link } from '@reach/router';

const RouterLink = styled(Link)`
  text-decoration: none;
  color: ${({ color, theme: { colors } }) => color || colors.white};
  :hover {
    text-decoration: underline;
  }
`;

export default ({ to, color, children, ...props }) => (
  <Box {...props}>
    <RouterLink color={color} to={to}>
      {children}
    </RouterLink>
  </Box>
);
