import React from 'react';
import { observable } from 'mobx';
import { toast } from 'react-toastify';
import { inject, observer } from 'mobx-react';
import { navigate } from '@reach/router';
import styled from '@emotion/styled';
import API from '../app/api';
import { APP_ROUTES, API_ROUTES } from '../app/routes';
import Loader from '../common/components/Loader';

const Iframe = styled('iframe')`
  width: 100vw;
  height: 100vh;
`;

@inject('orderStore')
@observer
class PdfPage extends React.Component {
  @observable pdfUrl = null;

  componentDidMount() {
    this.createPdfLink();
  }

  createPdfLink = async () => {
    const { pdf } = this.props;
    try {
      const { data } = await API(API_ROUTES.ORDER_PDF(pdf), {
        responseType: 'blob',
      });
      const file = new Blob([data], { type: 'application/pdf' });
      this.pdfUrl = URL.createObjectURL(file);
    } catch (e) {
      if (e.response.status === 401) {
        toast.info('Zaloguj się, aby zobaczyć dokument.');
        return navigate(`${APP_ROUTES.LOGIN}?redirect=/pdf/${pdf}`);
      }
      toast.info('Nie znaleziono dokumentu.');
      return navigate(APP_ROUTES.ROOT);
    }
  };

  render() {
    if (!this.pdfUrl) {
      return <Loader mt={100} />;
    }
    return <Iframe src={this.pdfUrl} />;
  }
}

export default PdfPage;
