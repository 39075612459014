import { Image, Flex } from 'rebass';
import styled from '@emotion/styled';
import React from 'react';
import ReactPaginate from 'react-paginate';

const Styles = styled(Flex)`
  position: relative;
  display: inline-flex;
  border-radius: 32px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  padding: 10px;
  ul {
    display: flex;
    align-items: center;
    li {
      cursor: pointer;
      &.active {
        font-family: Comfortaa-Bold;
      }
      :not(.active):hover {
        color: ${({ theme }) => theme.colors.red};
      }
      transition: color 0.5s;
      a {
        padding: 10px 13px;
        outline: none;
      }
      &.next,
      &.previous {
        a {
          padding: 0px;
        }
      }
    }
  }
`;

const Pagination = ({ onPageChange, ...props }) => {
  const { forcePage, pageCount = 1 } = props;
  const prevDisabled = +forcePage + 1 === 1;
  const nextDisabled = +forcePage + 1 === pageCount;
  if (+pageCount < 2) {
    return null;
  }
  return (
    <Styles>
      <ReactPaginate
        previousLabel={
          <Flex
            alignItems="center"
            bg={prevDisabled ? 'lightGray' : 'red'}
            color="red"
            sx={{
              borderRadius: '50%',
              cursor: prevDisabled ? 'not-allowed' : 'pointer',
            }}
            p={10}
          >
            <Image src="/images/left-arrow.svg" width="20px" />
          </Flex>
        }
        nextLabel={
          <Flex
            alignItems="center"
            bg={nextDisabled ? 'lightGray' : 'red'}
            color="red"
            sx={{
              borderRadius: '50%',
              cursor: nextDisabled ? 'not-allowed' : 'pointer',
            }}
            p={10}
          >
            <Image
              src="/images/left-arrow.svg"
              width="20px"
              sx={{ transform: 'rotate(180deg)' }}
            />
          </Flex>
        }
        breakLabel="..."
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={({ selected }) => onPageChange(+selected + 1)}
        activeClassName="active"
        {...props}
      />
    </Styles>
  );
};

export default Pagination;
