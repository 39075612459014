import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import HamburgerMenu from 'react-hamburger-menu';
import { Flex, Box, Image, Text, Button } from 'rebass';
import styled from '@emotion/styled';
import RouterLink from '../RouterLink';
import { APP_ROUTES } from '../../../app/routes';
import { colors } from '../../../app/theme';

export const Wrapper = styled(Flex)`
  width: 100%;
  color: ${({ theme }) => theme.colors.gray};
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  align-items: center;
  z-index: 250;
  height: 80px;
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.16);
`;

const MenuItemsContainer = styled(Flex)`
  @media (max-width: 960px) {
    position: fixed;
    top: 80px;
    width: 100%;
    left: 0;
    background-color: #fff;
    height: ${props => (props.isMobileOpen ? 'calc(100vh - 80px)' : '0px')};
    overflow: hidden;
    transition: height 0.25s ease-in;
  }
`;

@inject('authStore', 'userStore')
@observer
class NavBar extends Component {
  ref = React.createRef();

  @observable isMobileOpen = false;

  componentDidMount() {
    document.addEventListener('mouseup', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname },
    } = this.props;
    if (prevProps.location.pathname !== pathname) {
      this.isMobileOpen = false;
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.ref.current && !this.ref.current.contains(event.target)) {
      this.isMobileOpen = false;
      document.body.style.overflow = '';
    }
  };

  toggleHamburger = () => {
    document.body.style.overflow = !this.isMobileOpen ? 'hidden' : '';
    this.isMobileOpen = !this.isMobileOpen;
  };

  render() {
    const {
      authStore: { logout, isAuthenticated },
      userStore: {
        profile: {
          data: { number },
        },
      },
    } = this.props;
    return (
      <Wrapper bg="#fff" ref={this.ref}>
        <Flex
          px={15}
          sx={{ maxWidth: 1400, height: '100%' }}
          width={1}
          mx="auto"
          alignItems="center"
          justifyContent="space-between"
        >
          <RouterLink to={APP_ROUTES.ROOT} height="100%">
            <Flex height="100%">
              <Image
                height={50}
                src="/images/aimedo-logo.png"
                alignSelf="center"
              />
            </Flex>
          </RouterLink>
          {isAuthenticated && (
            <>
              <Box
                sx={{
                  '@media only screen and (min-width: 960px)': {
                    display: 'none',
                  },
                }}
              >
                <HamburgerMenu
                  isOpen={this.isMobileOpen}
                  menuClicked={this.toggleHamburger}
                  width={20}
                  height={15}
                  strokeWidth={2}
                  rotate={0}
                  color={colors.gray}
                  borderRadius={0}
                  animationDuration={0.5}
                />
              </Box>
              <MenuItemsContainer
                as="ul"
                isMobileOpen={this.isMobileOpen}
                flexDirection={['column', 'column', 'row']}
                alignItems="center"
                ml={[0, 0, 10]}
              >
                {number && (
                  <>
                    <Text
                      as="li"
                      fontSize={18}
                      pb={10}
                      pt={[40, 40, 10]}
                      mr={[10, 10, 10, 30]}
                      alignSelf="center"
                    >
                      {`login: ${number}`}
                    </Text>
                  </>
                )}
                <Button
                  py="15px"
                  px={15}
                  mb={[20, 20, 0]}
                  minWidth="100px"
                  onClick={logout}
                >
                  Wyloguj
                </Button>
              </MenuItemsContainer>
            </>
          )}
        </Flex>
      </Wrapper>
    );
  }
}

export default NavBar;
