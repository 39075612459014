import { Router, Redirect } from '@reach/router';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { APP_ROUTES } from './app/routes';
import LoginPage from './login/LoginPage';
import BaseLayout from './common/layouts/BaseLayout';
import Private from './common/components/Private';
import HomePage from './home/HomePage';
import VouchersSummaryPage from './vouchersSummary/VouchersSummaryPage';
// import VouchersRedeemPage from './vouchersRedeem/VouchersRedeemPage';
// import VoucherHistory from './voucherHistory/VoucherHistory';
// import MapPage from './map/MapPage';
import PdfPage from './pdf/PdfPage';
import StatuePage from './statue/StatutePage';
import CookiesPolicyPage from './cookiesPolicy/CookiesPolicyPage';
import ContactPage from './contact/ContactPage';
import HistoryTracker from './common/components/HistoryTracker';
import PrivacyPolicyPage from './privacyPolicy/PrivacyPolicyPage';

@inject('userStore', 'authStore')
@observer
class App extends React.Component {
  async componentDidMount() {
    const {
      authStore: { isAuthenticated },
      userStore: { fetchProfile },
    } = this.props;
    if (isAuthenticated) {
      fetchProfile();
    }
  }

  render() {
    return (
      <Router>
        <HistoryTracker path={APP_ROUTES.ROOT}>
          <BaseLayout path={APP_ROUTES.ROOT}>
            <Private path={APP_ROUTES.ROOT}>
              <HomePage path={APP_ROUTES.ROOT} />
              <VouchersSummaryPage path={APP_ROUTES.VOUCHERS_SUMMARY} />
              {/* <VouchersRedeemPage path={APP_ROUTES.VOUCHERS_REDEEM()} />
              <VoucherHistory path={APP_ROUTES.VOUCHER_HISTORY} />
              <VoucherHistory path={APP_ROUTES.VOUCHER_HISTORY_BY_ORDER_ID()} />
              <MapPage path={APP_ROUTES.MAP} /> */}
              <Redirect from="*" to={APP_ROUTES.ROOT} noThrow />
            </Private>
            <LoginPage path={APP_ROUTES.LOGIN} />
            <StatuePage path={APP_ROUTES.STATUTE} />
            <CookiesPolicyPage path={APP_ROUTES.COOKIE_POLICY} />
            <PrivacyPolicyPage path={APP_ROUTES.PRIVACY_POLICY} />
            <ContactPage path={APP_ROUTES.CONTACT} />
          </BaseLayout>
          <PdfPage path={APP_ROUTES.PDF} />
          <Redirect
            default
            from={APP_ROUTES.ROOT}
            to={APP_ROUTES.LOGIN}
            noThrow
          />
        </HistoryTracker>
      </Router>
    );
  }
}

export default App;
