import React, { useRef, useEffect } from 'react';
import { Box, Card } from 'rebass';
import styled from '@emotion/styled';

const Container = styled(Box)`
  position: fixed;
  top: 0;
  z-index: 2000;
  background-color: rgba(170, 170, 170, 0.5);
  width: 100vw;
  height: 100vh;
`;

const OverlayWrapper = styled(Card)`
  background-color: #fff;
  border-radius: 7px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 45px);
  max-width: 770px;
  overflow-y: scroll;
  max-height: 95vh;
`;

const Overlay = ({ onClose, children, forwardRef, ...props }) => {
  const ref = useRef(null);
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClose();
    }
  };
  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);
    document.body.style.overflow = 'hidden';
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
      document.body.style.overflow = '';
    };
  }, []);
  return (
    <Container>
      <OverlayWrapper ref={ref} {...props}>
        {typeof children === 'function' ? children({ onClose }) : children}
      </OverlayWrapper>
    </Container>
  );
};

export default Overlay;
