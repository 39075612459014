import theme from '@rebass/preset';

export const colors = {
  white: '#FFF',
  darkGreen: '#6D9700',
  green: '#92D400',
  lightGray: '#AAAAAA',
  lighterGray: '#F5F5F5',
  gray: '#545555',
  darkGray: '#9B9B9B',
  red: '#EE204B',
  lightRed: '#F9ABC5',
  lighterRed: '#F3E1E2',
};

export default {
  ...theme,
  breakpoints: ['640px', '960px', '1200px'],
  colors: {
    ...theme.colors,
    ...colors,
  },
  buttons: {
    ...theme.buttons,
    primary: {
      ...theme.buttons.primary,
      cursor: 'pointer',
      backgroundColor: colors.red,
      color: colors.white,
      borderRadius: '8px',
      minWidth: 150,
      fontWeight: 500,
      padding: '17px 20px',
      border: `2px solid ${colors.red}`,
      '&:disabled': {
        cursor: 'not-allowed',
      },
      transition: 'color 0.5s, background-color 0.5s, border 0.5s',
      ':hover': {
        color: colors.red,
        backgroundColor: '#fff',
      },
      outline: 'none',
    },
    secondary: {
      ...theme.buttons.primary,
      cursor: 'pointer',
      backgroundColor: colors.lightGray,
      transition: 'all .3s ease',
      padding: '17px 20px',
      color: colors.white,
      borderRadius: '8px',
      minWidth: 150,
      fontWeight: 500,
      '&:hover': {
        backgroundColor: colors.red,
      },
    },
    outline: {
      ...theme.buttons.outline,
      cursor: 'pointer',
      backgroundColor: colors.red,
      color: colors.white,
      borderRadius: '8px',
      border: `1px solid ${colors.white}`,
      fontWeight: 500,
      minWidth: 150,
      transition: 'background-color 0.5s',
      ':hover': {
        textDecoration: 'underline',
      },
    },
  },
  variants: {
    ...theme.variants,
    card: {
      ...theme.variants.card,
      borderRadius: 7,
      boxShadow: '0px 3px 9px 0px rgba(0, 0, 0, 0.16)',
    },
  },
  fonts: {
    body: 'Comfortaa-Light, sans-serif',
    heading: 'Comfortaa-Bold',
  },
};
