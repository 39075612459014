import { action, observable } from 'mobx';
import { toast } from 'react-toastify';
import { API_ROUTES } from '../app/routes';
import API from '../app/api';

class MapStore {
  @observable locations = {
    isLoading: false,
    data: [],
    error: null,
  };

  @observable currentPosition = {
    isLoading: false,
    position: {},
    fetched: false,
  };

  @action fetchCurrentLocation = () =>
    new Promise((resolve, reject) => {
      if (this.currentPosition.fetched) {
        return resolve(this.currentPosition.position);
      }
      const options = {
        enableHighAccuracy: true,
        maximumAge: 0,
      };
      this.currentPosition.isLoading = true;

      const success = ({ coords }) => {
        this.currentPosition = {
          position: { latitude: coords.latitude, longitude: coords.longitude },
          isLoading: false,
          fetched: true,
        };
        return resolve({
          latitude: coords.latitude,
          longitude: coords.longitude,
        });
      };

      const error = err => {
        this.currentPosition.isLoading = false;
        this.currentPosition.fetched = true;
        return reject(err.message);
      };

      if (navigator && navigator.geolocation) {
        return navigator.geolocation.getCurrentPosition(
          success,
          error,
          options,
        );
      }
      return error({ message: 'Error.' });
    });

  @action fetchLocations = async ({ lat, lng }) => {
    this.locations.isLoading = true;
    this.locations.firstRequest = false;
    this.locations.data = [];
    try {
      const { data: pharmacies } = await API.post(API_ROUTES.PHARMACIES, {
        location: { latitude: lat, longitude: lng },
        radius: 5,
      });
      this.locations.data = pharmacies;
    } catch (e) {
      this.locations.error = e.message;
      toast.error(e.message);
    } finally {
      this.locations.isLoading = false;
    }
  };
}

export default new MapStore();
