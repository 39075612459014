import React from 'react';
import { Flex, Text, Image, Box } from 'rebass';
import styled from '@emotion/styled';

const STEPS = ['Wybierz rabat', 'Pobierz kod', 'Zrealizuj kod w punkcie'];

const Wrapper = styled(Box)`
  position: relative;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    :not(:last-of-type) {
      &:after {
        content: ' ';
        width: 55px;
        height: 39px;
        background-image: url(/images/arrow.png);
        top: 50%;
        right: -30px;
        position: absolute;
        transform: translateY(-50%);
      }
    }
  }
`;

class Steps extends React.Component {
  imageSrc = index => {
    if (index === 0) {
      return '/images/aimedo-logo.png';
    }
    if (index === 1) {
      return '/images/discount-icon.svg';
    }
    return `/images/step-${index + 1}-active.svg`;
  };

  render() {
    const { activeStep } = this.props;
    return (
      <Flex flexDirection="column" mb={[20, 40, 69]}>
        <Text
          fontSize={25}
          lineHeight={1.4}
          mb={37}
          fontFamily="Comfortaa-Medium"
        >
          Jak korzystać ze strefy rabatów Aimedo BENEFIT?
        </Text>
        <Flex mx={[10, 10, -37]} flexWrap="wrap">
          {STEPS.map((step, index) => (
            <Wrapper
              textAlign="center"
              width={[1, 1 / 3, 1 / 3]}
              px={[10, 10, 37]}
              mb={37}
              key={index}
            >
              <Image
                display={[index === 0 ? 'none' : 'inline', 'inline']}
                src={this.imageSrc(index)}
                mb={30}
                height={100}
                width={200}
                sx={{
                  filter: activeStep !== index + 1 && 'grayscale(100%)',
                  objectFit: 'contain',
                  transform: index === 1 && 'scale(1.6)',
                }}
              />
              <Text
                maxWidth={250}
                fontSize={20}
                fontFamily="Comfortaa-Semibold"
                mx="auto"
              >
                {step}
              </Text>
            </Wrapper>
          ))}
        </Flex>
      </Flex>
    );
  }
}

export default Steps;
