import React from 'react';
import { get, includes } from 'lodash';
import { Image, Flex, Text, Button, Box } from 'rebass';
import Overlay from '../common/components/Overlay';
import { colors } from '../app/theme';
import Loader from '../common/components/Loader';

const BonDetailsOverlay = ({ onClose, discount = {}, isLoading }) => {
  const [copied, setCopied] = React.useState(false);

  const copyToClipboard = code => {
    const textField = document.createElement('textarea');
    textField.innerText = code;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    setCopied(true);
  };

  return (
    <Overlay onClose={onClose} p={0}>
      {isLoading ? (
        <Loader my="100px" />
      ) : (
        <>
          <Flex
            justifyContent="space-between"
            sx={{ borderBottom: `2px solid ${colors.lightGray}` }}
            pt={30}
          >
            <Text
              pl={[15, 30, 40, 80]}
              fontSize={28}
              py={[15, 25, 37]}
              fontFamily="Comfortaa-Bold"
            >
              Szczegóły rabatu
            </Text>
            <Image
              height={23}
              src="/images/x.png"
              sx={{ cursor: 'pointer' }}
              onClick={onClose}
              mr={28}
            />
          </Flex>
          <Flex flexDirection="column" py={20} px={[15, 30, 40, 80]}>
            <Text
              fontSize={17}
              mb={20}
              fontFamily="Comfortaa-Bold"
              lineHeight={1.5}
              className="wyswig"
              dangerouslySetInnerHTML={{
                __html: `<span style="color:#EE204B; margin-right: 5px;">${discount.discountInfo}</span>${discount.name}`,
              }}
            />
            <Text
              fontSize={14}
              lineHeight={1.3}
              className="wyswig"
              dangerouslySetInnerHTML={{
                __html: discount.description,
              }}
            />
          </Flex>
          <Flex
            p={[20, 30, 40, 50]}
            alignItems="center"
            justifyContent="center"
            flexDirection={['column', 'row']}
            bg="lighterRed"
          >
            {discount.code ? (
              <Flex alignItems="center" justifyContent="center" flexWrap="wrap">
                <Text fontSize={24} mr={[10]} lineHeight={1.2}>
                  KOD RABATOWY:
                </Text>
                <Text
                  lineHeight={1.4}
                  fontSize={30}
                  fontFamily="Comfortaa-Bold"
                  color="red"
                  mr={[0, 0, 20]}
                  textAlign="center"
                >
                  {discount.code}
                </Text>
                <Box width={[1, 1, 'auto']} textAlign="center">
                  <Button
                    onClick={() => copyToClipboard(discount.code)}
                    mt={[15, 15, 0]}
                  >
                    {copied ? 'Skopiowano!' : 'Skopiuj kod'}
                  </Button>
                </Box>
                {discount.url && (
                  <Text
                    width={1}
                    as="a"
                    fontSize={20}
                    mt={25}
                    color="black"
                    sx={{
                      textDecoration: 'none',
                      ':hover': {
                        color: colors.red,
                        transition: 'all 0.4s',
                      },
                    }}
                    target="_blank"
                    alignSelf="center"
                    textAlign="center"
                    href={
                      includes(discount.url, 'http')
                        ? discount.url
                        : `http://${discount.url}`
                    }
                  >
                    {`Idź do ${get(discount, 'url', '')
                      .replace('https://', '')
                      .replace('http://', '')}`}
                  </Text>
                )}
              </Flex>
            ) : (
              <Flex flexDirection="column">
                {discount.url && (
                  <>
                    <Text
                      fontSize={24}
                      lineHeight={1.2}
                      mb={20}
                      textAlign="center"
                    >
                      Do skorzystania z tej oferty nie potrzebujesz kodu
                    </Text>
                    <Button
                      as="a"
                      target="_blank"
                      alignSelf="center"
                      href={
                        includes(get(discount, 'url'), 'http')
                          ? discount.url
                          : `http://${discount.url}`
                      }
                    >
                      {`Idź do ${get(discount, 'url', '')
                        .replace('https://', '')
                        .replace('http://', '')}`}
                    </Button>
                  </>
                )}
              </Flex>
            )}
          </Flex>
        </>
      )}
    </Overlay>
  );
};
export default BonDetailsOverlay;
