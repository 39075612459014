import React from 'react';
import ga from '../../app/googleAnalytics';

export default class HistoryTracker extends React.Component {
  componentDidMount() {
    this._collectPageView();
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname, href },
    } = this.props;
    if (pathname !== prevProps.location.pathname) {
      this._collectPageView();
    }
    const {
      location: { href: prevHref },
    } = prevProps;
    if (prevHref !== href) {
      window.scrollTo(0, 0);
    }
  }

  _collectPageView = () => {
    const {
      location: { href },
    } = this.props;
    if (ga) {
      ga.pageview(href);
    }
  };

  render() {
    const { children } = this.props;
    return children;
  }
}
