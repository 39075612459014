export const APP_ROUTES = {
  ROOT: '/',
  VOUCHERS_SUMMARY: '/podsumowanie',
  VOUCHERS_REDEEM: orderId =>
    orderId ? `/zamowienia/${orderId}` : '/zamowienia/:orderId',
  LOGIN: '/zaloguj',
  MAP: '/mapa',
  VOUCHER_HISTORY: '/historia-rabatow',
  VOUCHER_HISTORY_BY_ORDER_ID: orderId =>
    orderId ? `/historia-rabatow/${orderId}` : '/historia-rabatow/:orderId',
  PDF: '/pdf/:pdf',
  COOKIE_POLICY: '/polityka-cookies',
  PRIVACY_POLICY: '/polityka-prywatnosci',
  STATUTE: '/regulamin',
  CONTACT: '/kontakt',
};

export const API_ROUTES = {
  LOGIN: '/auth/login',
  OFFER: '/shops/offer',
  ORDER: '/orders',
  ORDER_CONFIRM: '/orders/confirm',
  HISTORY: '/orders/history',
  HISTORY_BY_ORDER_ID: orderId => `/orders/${orderId}/history`,
  PROFILE: '/profile',
  ORDER_PDF: orderId => `/pdf/${orderId}.pdf`,
  SEND_ORDER_PDF_TO_EMAIL: orderId => `/orders/${orderId}/email`,
  PHARMACIES: '/pharmacies',
  DISCOUNTS: '/discount/coupons',
  DISCOUNTS_CODE: discountId => `/discount/coupons/${discountId}/get_code`,
};
