import React from 'react';
import { Box } from 'rebass';

const BaseContainer = ({ children, ...props }) => (
  <Box
    mx="auto"
    my={0}
    px={[10, 20, 20]}
    w={1}
    sx={{
      maxWidth: 1400,
      zIndex: 1,
    }}
    {...props}
  >
    {children}
  </Box>
);

export default BaseContainer;
