/* eslint-disable */
export default [
  {
    description:
      'Administratorem danych osobowych zbieranych za pośrednictwem Serwisu aimedo.gift jest epruf s.a. z siedzibą w Łodzi, ul. Zbąszyńska 3, 91-342 Łódź, dane kontaktowe: ul. Zbąszyńska 3, 91-342 Łódź, tel. 48 200 75 68 (zwana dalej „Administratorem”) będąca właścicielem platformy internetowej www.aimedo.gift.',
  },

  {
    description:
      'Administrator w celu należytej ochrony danych osobowych powołał Inspektora Ochrony Danych. Kontakt do IOD: Małgorzata Sztobryn-Robak, e-mail: iod@epruf.pl',
  },

  {
    description:
      'Administrator przetwarza podane w formularzach dane osobowe Klienta w celach:',
    children: [
      {
        description:
          'realizacji usług wymiany Bonów towarowych aimedo.gift na Bony towarowe Akceptantów – podstawą prawną przetwarzania danych jest wykonanie umowy (art. 6 ust. 1 lit. b RODO),',
      },
      {
        description:
          'rozpatrzenia reklamacji, dochodzenia i obrony w razie zaistnienia wzajemnych roszczeń – podstawą prawną przetwarzania danych jest prawnie uzasadniony interes Wydawcy bonów (art. 6 ust. 1 lit. f RODO),',
      },
      {
        description:
          'pozyskania opinii Klienta o działaniach realizowanych przez Wydawcę i Akceptantów odnośnie przeprowadzonych Transakcji. Podstawą prawną przetwarzania danych osobowych jest prawnie uzasadniony interes administratora (art. 6 ust. 1 lit. f RODO),',
      },
      {
        description:
          'prowadzenia i bieżącego zarządzania kontami aimedo.gift w mediach społecznościowych, w tym w celu komunikowania się ze społecznością oraz organizowania wydarzeń lub promocji na zasadach określonych funkcjonalnościami poszczególnych mediów społecznościowych oraz zgodnie z ich regulaminami. Podstawą prawną przetwarzania danych jest zgoda, np. w formie kliknięcia na wtyczkę „Lubię to”, (art. 6 ust. 1 lit a RODO).',
      },
    ],
  },

  {
    description:
      'Podanie danych osobowych Klienta jest dobrowolne, lecz niezbędne w celu wykonania usług w odpowiedni sposób. Klient ma możliwość niepodawania swoich danych osobowych i w takim przypadku usługi są świadczone z wyłączeniem użycia poczty elektronicznej.',
  },

  {
    description:
      'Dane osobowe Klientów będą przetwarzane przez Wydawcę przez okres ważności Bonu towarowego aimedo.gift, a po jego zakończeniu do czasu upływu okresu przedawnienia roszczeń z tytułu korzystania z Bonu towarowego aimedo.gift, bądź w razie powstania sporu do czasu wygaśnięcia roszczeń stron. Dane osobowe przetwarzane w celach pozyskiwania opinii przechowywane będą do czasu wniesienia sprzeciwu.',
  },

  {
    description:
      'Dane osobowe Klientów mogą być ujawniane przez Administratora podmiotom z nim współpracującym (odbiorcom danych), tj.:',
    children: [
      {
        description:
          'podmiotom dostarczającym i wspierającym systemy informatyczne stosowane przez Administratora w celu obsługi Serwisu aimedo.gift,',
      },
      {
        description:
          'podmiotom świadczącym usługi związane z działalnością Wydawcy w celu realizacji przez niego obowiązków wynikających z Transakcji – na podstawie umowy zawartej między Wydawcą a Klientem w szczególności w zakresie: obsługi Transakcji, obsługi Klienta (telefonicznej i e-mailowej), a także podmiotowi świadczącemu usługę lub dostarczającemu narzędzia do kontaktu telefonicznego i elektronicznego z Klientem.',
      },
      {
        description:
          'podmiotom świadczącym usługi w zakresie zbierania opinii,',
      },
      {
        description:
          'dostawcom mediów społecznościowych, w szczególności Facebook Ireland Ltd, na mocy regulaminów tych mediów społecznościowych oraz przy zapewnieniu stosowania przez ww. podmioty adekwatnych środków technicznych i organizacyjnych zapewniających ochronę danych, a także podmiotom świadczącym usługi zarządzania i konsultingu w zakresie kont Serwisu aimedo.gift w mediach społecznościowych, na mocy stosownych umów powierzenia przetwarzania danych osobowych oraz przy zapewnieniu stosowania przez ww. podmioty adekwatnych środków technicznych i organizacyjnych zapewniających ochronę danych.',
      },
    ],
  },
  {
    description:
      'Każdej osobie, której dane są przetwarzane, w zakresie wynikającym z przepisów prawa, przysługuje prawo dostępu do swoich danych oraz ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych oraz prawo wniesienia sprzeciwu wobec przetwarzania danych, które można wykonać poprzez wysłanie wiadomość e-mail na adres pomoc@aimedo.gift lub telefonicznie pod numerem tel.: 42 612 09 91.',
  },

  {
    description:
      'W przypadku wątpliwości związanych z przetwarzaniem danych osobowych każda osoba może zwrócić się do Administratora z prośbą o udzielenie informacji. Niezależnie od powyższego, każdemu przysługuje prawo wniesienia skargi do organu nadzorczego – Prezesa Urzędu Ochrony Danych Osobowych.',
  },
];
