import React from 'react';
import { Flex, Text } from 'rebass';
import styled from '@emotion/styled';
import BaseContainer from '../common/layouts/BaseContainer';

const Title = styled(Text)`
  text-transform: uppercase;
  border-bottom: 1px solid ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.red};
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 15px;
`;

class ContactPage extends React.Component {
  render() {
    return (
      <BaseContainer my={50} px={[20, 35]}>
        <Flex width={1} flexDirection="column" sx={{ lineHeight: 1.5 }}>
          <Flex width={1} flexDirection="column" mb={30}>
            <Title>Biuro obsługi klienta</Title>
            <Flex width={1} justifyContent="space-between">
              <Flex flexDirection="column">
                <Text color="red">aimedo.gift</Text>
                <Text>Zbąszyńska 3</Text>
                <Text>91-342 Łódź</Text>
              </Flex>
              <Flex flexDirection="column">
                <Text textAlign="right">
                  Czynne w dni robocze od 8:00 do 16:00
                </Text>
                <Text textAlign="right">
                  Email:
                  <Text
                    ml="5px"
                    as="a"
                    color="gray"
                    href="mailto:pomoc@aimedo.gift"
                  >
                    pomoc@aimedo.gift
                  </Text>
                </Text>
                <Text textAlign="right">
                  Tel.:
                  <Text mx="5px" as="a" color="gray" href="tel:42 612 09 91">
                    42 612 09 91
                  </Text>
                  (opłata za połączenie wg stawki operatora)
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            width={1}
            flexDirection="column"
            mb={30}
            sx={{ lineHeight: 1.5 }}
          >
            <Title>Właściciel platformy</Title>
            <Flex width={1} justifyContent="space-between">
              <Flex flexDirection="column">
                <Text color="red">epruf s.a.</Text>
                <Text>Zbąszyńska 3</Text>
                <Text>91-342 Łódź</Text>
              </Flex>
              <Flex flexDirection="column">
                <Text textAlign="right">NIP: 9471958279</Text>
                <Text textAlign="right">REGON: 100592925</Text>
                <Text textAlign="right">
                  Email:
                  <Text
                    ml="5px"
                    as="a"
                    color="gray"
                    href="mailto:kontakt@epruf.pl"
                  >
                    kontakt@epruf.pl
                  </Text>
                </Text>
                <Text textAlign="right">
                  Tel.:
                  <Text ml="5px" as="a" color="gray" href="tel:42 612 09 91">
                    42 200 75 68
                  </Text>
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            width={1}
            flexDirection="column"
            mb={30}
            sx={{ lineHeight: 1.5 }}
          >
            <Title>Inspektor ochrony danych osobowych</Title>
            <Flex width={1} justifyContent="flex-end">
              <Text ml="5px" as="a" color="gray" href="mailto:IOD@epruf.pl">
                IOD@epruf.pl
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </BaseContainer>
    );
  }
}

export default ContactPage;
