import { action, observable, computed } from 'mobx';
import { navigate } from '@reach/router';
import isNumber from 'lodash/isNumber';
import get from 'lodash/get';
import { FORM_ERROR } from 'final-form';
import { toast } from 'react-toastify';
import pick from 'lodash/pick';
import API, { convertKeysToSnakeCase } from '../app/api';
import { API_ROUTES, APP_ROUTES } from '../app/routes';
import userStore from './userStore';
import offersStore from './offersStore';

export class OrderStore {
  @observable vouchers = {};

  @observable isSubmitting = false;

  @observable order = {
    isLoading: false,
    error: null,
    data: [],
  };

  @observable history = {
    isLoading: false,
    error: null,
    data: [],
  };

  @computed get total() {
    return (
      Object.values(this.vouchers)
        .map(value => value.total)
        .reduce((acc, val) => acc + val, 0) || 0
    );
  }

  @computed get amountToSpare() {
    const { balance = 0 } = userStore.profile.data;
    return balance - this.total;
  }

  @computed get isOrderOverLimit() {
    const { balance = 0 } = userStore.profile.data;
    return this.total > balance;
  }

  @computed get summary() {
    return Object.values(this.vouchers);
  }

  @action addVoucher = (offer, values, allowDelete = true) => {
    if (values.voucherId && isNumber(values.quantity)) {
      const { balance = 0 } = userStore.profile.data;
      const voucher =
        offer.vouchers.find(v => v.id === +values.voucherId) || {};

      const prevQuantity = get(this.vouchers[offer.code], 'quantity', 0);
      const prevVoucherId = get(this.vouchers[offer.code], 'voucherId');

      const voucherValue = voucher.value * values.quantity;
      const total =
        (Object.values(this.vouchers)
          .filter(v => prevVoucherId !== v.voucherId)
          .map(value => value.total)
          .reduce((acc, val) => acc + val, 0) || 0) + voucherValue;

      if (
        (prevVoucherId !== values.voucherId ||
          prevQuantity < values.quantity) &&
        total > balance
      ) {
        toast.warn(
          'Operacja niedozwolona. Twoje zamówienie przekroczyło limit.',
        );
        return { [FORM_ERROR]: 'Operation not allowed.' };
      }

      this.vouchers = {
        ...this.vouchers,
        [offer.code]: {
          ...(this.vouchers[offer.code]
            ? { ...this.vouchers[offer.code] }
            : {}),
          ...values,
          total: values.quantity * (voucher.value || 0),
          offer,
        },
      };
    }
    if (allowDelete && (values.quantity === 0 || !values.voucherId)) {
      delete this.vouchers[offer.code];
    }
  };

  @action createOrder = async () => {
    this.isSubmitting = true;
    try {
      const { data } = await API.put(
        API_ROUTES.ORDER,
        convertKeysToSnakeCase({
          order_items: this.getOrderPayload(),
        }),
      );
      navigate(APP_ROUTES.VOUCHERS_SUMMARY);
      console.log(data);
    } catch (e) {
      console.log(e);
    } finally {
      this.isSubmitting = false;
    }
  };

  @action confirmOrder = async () => {
    this.isSubmitting = true;
    try {
      const {
        data: { number },
      } = await API.post(
        API_ROUTES.ORDER_CONFIRM,
        convertKeysToSnakeCase({
          order_items: this.getOrderPayload(),
        }),
      );
      navigate(APP_ROUTES.VOUCHERS_REDEEM(number));
      userStore.fetchProfile();
    } catch (e) {
      console.log(e);
    } finally {
      this.isSubmitting = false;
    }
  };

  @action fetchOrder = async () => {
    try {
      this.order.isLoading = true;
      const { data } = await API(API_ROUTES.ORDER);
      this.order.data = data;
      this.vouchers = {};
      data.forEach(orderItem => {
        const offer =
          offersStore.offers.data.find(o => o.code === orderItem.shopCode) ||
          {};
        this.addVoucher(
          offer,
          pick(orderItem, ['voucherId', 'quantity', 'validTo']),
        );
      });
    } catch (e) {
      this.order.error = e.message;
    } finally {
      this.order.isLoading = false;
    }
  };

  getOrderPayload = () =>
    Object.values(this.vouchers).map(({ voucherId, quantity }) => ({
      voucherId,
      quantity,
    }));

  @action fetchHistory = async orderId => {
    try {
      this.history = {
        isLoading: true,
        data: [],
        error: null,
      };
      const { data } = await API(
        orderId ? API_ROUTES.HISTORY_BY_ORDER_ID(orderId) : API_ROUTES.HISTORY,
      );
      this.history.data = data;
    } catch (e) {
      this.history.error = e.message;
    } finally {
      this.history.isLoading = false;
    }
  };

  @action sendPdfToEmail = async ({ email, orderId }) => {
    try {
      await API.post(API_ROUTES.SEND_ORDER_PDF_TO_EMAIL(orderId), { email });
      toast.info('Bony zostały wysłane na podany adres email.');
      navigate(APP_ROUTES.ROOT);
    } catch (e) {
      console.log(e);
    }
  };
}

export default new OrderStore();
