import React from 'react';
import { Flex, Text } from 'rebass';
import styled from '@emotion/styled';
import RouterLink from './RouterLink';
import { APP_ROUTES } from '../../app/routes';
import BaseContainer from '../layouts/BaseContainer';

export const Wrapper = styled(Flex)`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.darkBlueGray};
  z-index: 250;
  height: 230px;
  color: #fff;
`;

const Footer = () => (
  <Wrapper bg="red" alignItems="center">
    <BaseContainer width={1}>
      <Flex
        justifyContent={['center', 'center', 'space-between']}
        flexWrap="wrap"
        width={1}
      >
        <Flex
          flexDirection={['column', 'column', 'row']}
          width={[1, 'auto']}
          alignItems="center"
        >
          <RouterLink to={APP_ROUTES.STATUTE} mr={20} my={['8px', 10]}>
            Regulamin
          </RouterLink>
          <RouterLink to={APP_ROUTES.COOKIE_POLICY} mr={20} my={['8px', 10]}>
            Polityka cookies
          </RouterLink>
          <RouterLink to={APP_ROUTES.CONTACT} mr={20} my={['8px', 10]}>
            Kontakt
          </RouterLink>
          <Text mr={20} my={['8px', 10]}>
            Infolinia:
            <Text ml="5px" as="a" color="#fff" href="tel:42 612 09 91">
              42 612 09 91
            </Text>
          </Text>
        </Flex>
        <Text
          alignSelf="center"
          my={['8px', 10]}
          width={[1, 1, 'auto']}
          textAlign="center"
        >
          Wszelkie prawa zastrzeżone © 2020 – epruf s.a.
        </Text>
      </Flex>
    </BaseContainer>
  </Wrapper>
);

export default Footer;
