import React from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from '@reach/router';

import { APP_ROUTES } from '../../app/routes';

@inject('authStore')
@observer
class Private extends React.Component {
  render() {
    const {
      authStore: { isAuthenticated },
      children,
    } = this.props;
    if (!isAuthenticated) {
      return <Redirect from="" to={APP_ROUTES.LOGIN} noThrow />;
    }
    return children;
  }
}

export default Private;
