import React from 'react';
import { Flex, Box, Text } from 'rebass';
import { observable } from 'mobx';
import qs from 'query-string';
import { isEmpty, map } from 'lodash';
import { inject, observer } from 'mobx-react';
import BaseContainer from '../common/layouts/BaseContainer';
import Loader from '../common/components/Loader';
import DiscountCard from './DiscountCard';
import BonDetailsOverlay from './BonDetailsOverlay';
import Steps from './Steps';
import LayoutWithBanner from '../common/layouts/LayoutWithBanner';
import Pagination from '../common/components/Pagination';

@inject('discountsStore')
@observer
class HomePage extends React.Component {
  @observable isOpen = false;

  @observable voucherDetails = {};

  async componentDidMount() {
    const {
      location: { search },
      discountsStore: { fetchDiscounts },
    } = this.props;
    const { page = 1 } = qs.parse(search);
    fetchDiscounts(page);
  }

  onPageChange = page => {
    const { navigate } = this.props;
    navigate(`?page=${page}`);
    const {
      discountsStore: { fetchDiscounts },
    } = this.props;
    fetchDiscounts(page);
  };

  render() {
    const {
      location: { search },
      discountsStore: {
        discounts,
        paging,
        isLoading: isLoadingDiscounts,
        isLoadingSingle,
        fetchDiscountById,
        clearDiscount,
        discount,
      },
    } = this.props;
    const { page = 1 } = qs.parse(search);

    return (
      <>
        <LayoutWithBanner>
          <BaseContainer>
            <Steps activeStep={1} />
            <Flex flexDirection="column">
              {isLoadingDiscounts && <Loader mb={[15, 15, 15, 32]} py="70px" />}
              {isEmpty(discounts) && !isLoadingDiscounts && (
                <Text fontSize={32} my={[30, 60, 90]} textAlign="center">
                  Przepraszamy, ale lista kodów jest pusta
                </Text>
              )}
              <Flex
                flexWrap="wrap"
                className="discount-list"
                mx={[-10, -10, -10, -37]}
                mb={[15, 15, 15, 32]}
              >
                {!isLoadingDiscounts &&
                  map(discounts, d => (
                    <DiscountCard
                      key={`discount-card-key-${d.id}`}
                      {...d}
                      findOutMore={() => {
                        this.isOpen = true;
                        fetchDiscountById(d, () => {
                          this.isOpen = false;
                        });
                      }}
                      isLoading={isLoadingSingle}
                    />
                  ))}
              </Flex>
              <Box pb={60} mx="auto">
                <Pagination
                  forcePage={+page - 1}
                  pageCount={paging.pages}
                  onPageChange={this.onPageChange}
                />
              </Box>
            </Flex>
          </BaseContainer>
        </LayoutWithBanner>
        {this.isOpen && (
          <BonDetailsOverlay
            onClose={() => {
              this.isOpen = false;
              clearDiscount();
            }}
            isLoading={isLoadingSingle}
            discount={discount}
          />
        )}
      </>
    );
  }
}

export default HomePage;
