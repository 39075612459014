import { createGlobalStyle } from 'styled-components';

const Global = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  @font-face {
    font-family: 'Comfortaa-Bold';
    src: url('/fonts/Comfortaa-Bold.ttf');
  }
  @font-face {
    font-family: 'Comfortaa-Light';
    src: url('/fonts/Comfortaa-Light.ttf');
  }
  @font-face {
    font-family: 'Comfortaa-Medium';
    src: url('/fonts/Comfortaa-Medium.ttf');
  }
  @font-face {
    font-family: 'Comfortaa-Regular';
    src: url('/fonts/Comfortaa-Regular.ttf');
  }
  @font-face {
    font-family: 'Comfortaa-SemiBold';
    src: url('/fonts/Comfortaa-SemiBold.ttf');
  }
  body {
    font-family: Comfortaa-Light, sans-serif;
    line-height: 1.2;
    color: #545555;
  }
  strong {
    font-family: 'Comfortaa-Bold';
  }
  i {
    font-style: italic;
  }
  b {
    font-family: 'Comfortaa-Bold';
  }
  em {
    font-style: italic;
  }
  small {
    font-size: smaller;
  }
  sub {
    vertical-align: sub;
    font-size: smaller;
  }
  sup {
    vertical-align: super;
    font-size: smaller;
  }
  ins {
    text-decoration: underline;
  }
  del {
    text-decoration: line-through;
  }
  mark {
    background-color: yellow;
    color: black;
  }
  a {
    color: black;
  }
  .wyswig {
    p {
      margin-bottom: 8px;
    }
  }
`;

export default Global;
