import { action, observable } from 'mobx';
import moment from 'moment';
import { toast } from 'react-toastify';
import API from '../app/api';
import { API_ROUTES } from '../app/routes';
import errorHandler from '../common/utils/errorHandler';

export class UserStore {
  @observable profile = {
    isLoading: false,
    data: {},
  };

  @action fetchProfile = async () => {
    this.profile.isLoading = true;
    try {
      const { data = {} } = await API(API_ROUTES.PROFILE);
      const { validTo } = data;
      if (validTo && moment.utc(validTo).isBefore(moment.utc())) {
        toast.error(
          `Termin ważności Twojej karty upłynął ${moment
            .utc(validTo)
            .format('DD/MM/YYYY')}`,
        );
      }
      this.profile.data = data;
    } catch (e) {
      return errorHandler(e);
    } finally {
      this.profile.isLoading = false;
    }
  };

  @action resetProfile = () => {
    this.profile.data = {};
  };
}

export default new UserStore();
