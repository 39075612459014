import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import qs from 'query-string';
import { navigate } from '@reach/router';
import styled from '@emotion/styled';
import { Box, Flex, Text, Card, Link } from 'rebass';
import LoginForm from './LoginForm';
import { APP_ROUTES } from '../app/routes';

const AttenntionContainer = styled(Flex)`
  max-width: 700px;
  text-align: center;
`;
const Wrapper = styled(Flex)`
  width: 100%;
  position: relative;
  background: url('/images/banner-discounts.svg') center center no-repeat;
  background-size: contain;
  background-position: top;
  background-color: #f3e1e2;
`;

@inject('authStore')
@observer
class LoginPage extends Component {
  componentDidMount() {
    const {
      authStore: { isAuthenticated },
    } = this.props;
    if (isAuthenticated) {
      setTimeout(() => {
        // without timeout root page doesnt show up
        navigate(APP_ROUTES.ROOT);
      }, 0);
    }
  }

  render() {
    const {
      authStore: { signIn },
      location: { search },
    } = this.props;
    const { redirect } = qs.parse(search);
    return (
      <Wrapper
        alignItems="center"
        bg="#F3E1E2"
        flexDirection="column"
        minHeight="calc(100vh - 80px)"
        justifyContent="center"
        p={10}
      >
        <Card maxWidth="1000px" p={0}>
          <Flex justifyContent="space-between" flexWrap="wrap">
            <Flex
              flexDirection="column"
              bg="red"
              width={[1, 0.45]}
              p={[30, 50, 80]}
              sx={{
                borderTopLeftRadius: 8,
                borderTopRightRadius: [8, 0],
                borderBottomLeftRadius: [0, 8],
              }}
            >
              <Text
                fontSize={[20, 24]}
                color="#fff"
                fontFamily="Comfortaa-Semibold"
                mb={[15, 30]}
                lineHeight={1.4}
              >
                Witamy w Strefie Rabatów Aimedo BENEFIT dostępnej wyłącznie dla
                Pracowników Spółek Grupy Pelion!
              </Text>
              <Text fontSize={[16, 18]} color="#fff" lineHeight={1.3}>
                Aby korzystać z ofert i rabatów przygotowanych przez naszych
                Partnerów, zaloguj się za pomocą otrzymanego loginu i hasła
                (CVV).
              </Text>
            </Flex>
            <Box width={[1, 0.55]} p={[30, 50, 80]}>
              <Text fontSize={28} mb={10} textAlign="center">
                Zaloguj się
              </Text>
              <LoginForm onSubmit={values => signIn({ ...values, redirect })} />
            </Box>
          </Flex>
        </Card>
        <AttenntionContainer
          px={15}
          mt={35}
          mb={30}
          justifyContent="center"
          flexDirection="column"
          lineHeight={1.3}
        >
          <Flex width={1} flexWrap={['wrap', 'nowrap']}>
            <Text color="red" width={[1, 70]} mr="5px" textAlign="center">
              Uwaga!
            </Text>
            <Text width={[1, 'auto']}>
              Pamiętaj, nie udostępniaj nikomu loginu ani hasła (CVV).
            </Text>
          </Flex>
          <Text>
            W przypadku problemów z zalogowaniem się, skontaktuj się z nami:
          </Text>
          <Text>
            telefonicznie pod numerem
            <Link ml="3px" color="gray" href="tel:426120991" mx="3px">
              42 612 09 91
            </Link>
            lub mailowo:
            <Link ml="3px" color="gray" href="mailto:pomoc@aimedo.gift">
              pomoc@aimedorabaty.pl
            </Link>
          </Text>
        </AttenntionContainer>
      </Wrapper>
    );
  }
}

export default LoginPage;
