import React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { Flex, Text, Box } from 'rebass';
import Input from '../common/form/Input';
import SubmitButton from '../common/form/SubmitButton';
import { composeValidators, required } from '../common/utils/formValidators';
import Checkbox from '../common/form/Checkbox';
import { APP_ROUTES } from '../app/routes';
import RouterLink from '../common/components/RouterLink';

const LoginForm = ({ onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    initialValues={{ isCaptchaValid: false }}
    render={({ handleSubmit, submitting }) => (
      <form onSubmit={handleSubmit} autoComplete="off">
        <Flex flexDirection="column" alignItems="flex-start">
          <FormSpy subscription={{ submitError: true }}>
            {({ submitError }) => (
              <>
                {submitError && (
                  <Text mb={10} fontSize={13} color="red">
                    {submitError}
                  </Text>
                )}
              </>
            )}
          </FormSpy>
          <Field
            label="Login"
            name="number"
            component={Input}
            validate={composeValidators(required)}
            autoComplete="off"
          />
          <Field
            label="Hasło (CVV)"
            name="password"
            type="password"
            component={Input}
            validate={composeValidators(required)}
            autoComplete="off"
          />
          <Field
            type="checkbox"
            label={
              <Text>
                Wejście do serwisu oznacza akceptację{' '}
                <RouterLink
                  sx={{ display: 'inline' }}
                  color="#545555"
                  to={APP_ROUTES.STATUTE}
                >
                  regulaminu
                </RouterLink>
              </Text>
            }
            name="bylawsAcceptance"
            component={Checkbox}
            validate={composeValidators(required)}
          />
          <Box mt={25} />
          <SubmitButton mx="auto" mt={10} py={17}>
            {submitting ? 'Proszę czekać...' : 'WEJDŹ'}
          </SubmitButton>
        </Flex>
      </form>
    )}
  />
);

export default LoginForm;
