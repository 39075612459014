import { navigate } from '@reach/router';
import { action, observable } from 'mobx';
import { FORM_ERROR } from 'final-form';
import get from 'lodash/get';
import API, { setToken, clearToken } from '../app/api';
import { ACCESS_TOKEN_KEY } from '../app/constants';
import { API_ROUTES, APP_ROUTES } from '../app/routes';
import userStore from './userStore';

export class AuthStore {
  constructor() {
    API.interceptors.response.use(undefined, error => {
      if (error.response.status === 401) {
        this.logout();
      }
      return Promise.reject(error);
    });
  }

  @observable isAuthenticated = !!localStorage.getItem(ACCESS_TOKEN_KEY);

  @observable isLoading = false;

  @action signIn = async ({ number, password, redirect, bylawsAcceptance }) => {
    this.isLoading = true;
    try {
      const {
        data: { token },
      } = await API.post(
        `${API_ROUTES.LOGIN}?number=${number}&password=${password}&bylawsAcceptance=${bylawsAcceptance}`,
      );
      setToken({ token });
      userStore.fetchProfile();
      this.isAuthenticated = true;
      navigate(redirect || APP_ROUTES.ROOT);
    } catch (e) {
      this.isAuthenticated = false;
      clearToken();
      const error = get(
        e,
        'response.data.error',
        'Nieprawidłowe dane logowania. Po 5 błędnych próbach Twoje konto zostanie zablokowane.',
      );
      return { [FORM_ERROR]: error };
    } finally {
      this.isLoading = false;
    }
  };

  @action logout = async () => {
    clearToken();
    this.isAuthenticated = false;
    userStore.resetProfile();
    navigate(APP_ROUTES.LOGIN);
  };
}

export default new AuthStore();
