import React from 'react';
import { Box, Button, Card, Text } from 'rebass';
import HorizontalLine from '../common/components/HorizontalLine';

class DiscountCard extends React.Component {
  render() {
    const { logoUrl, name, findOutMore, url, discountInfo } = this.props;
    return (
      <Box
        width={[1, 1 / 2, 1 / 3]}
        px={[10, 10, 15, 37]}
        pb={[20, 20, 20, 37]}
      >
        <Card
          p={[15, 20, 15, 30]}
          height="100%"
          display="flex"
          sx={{ flexDirection: 'column' }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: 200,
              height: 80,
              backgroundImage: `url(${logoUrl})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
            mx="auto"
            mb={30}
          />
          <Text
            fontSize={17}
            lineHeight={1.5}
            mb="25px"
            sx={{ minHeight: 30 }}
            fontFamily="Comfortaa-Bold"
            className="wyswig"
            dangerouslySetInnerHTML={{
              __html: `<span style="color:#EE204B; margin-right: 5px;">${discountInfo}</span>${name}`,
            }}
          />
          <Box mt="auto" width={1}>
            {url && (
              <Text
                color="red"
                fontSize={15}
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  ':hover': { textDecoration: 'underline' },
                }}
                as="a"
                href={url}
                rel="noopener noreferrer"
                target="_blank"
              >
                Dowiedz się więcej
              </Text>
            )}
            <HorizontalLine my={20} opacity={0.19} />
            <Button fontSize={15} onClick={findOutMore} width="100%">
              Skorzystaj
            </Button>
          </Box>
        </Card>
      </Box>
    );
  }
}

export default DiscountCard;
